<template>
  <div class="data-dim">
    <section class="dimension-statistics">
      <section class="filter" id="guideElement22">

        <label class="title">发布时间:
          <el-radio-group v-model="form.type" size="mini">
            <el-radio label="1" border>今天</el-radio>
            <el-radio label="2" border>最近三天</el-radio>
            <el-radio label="3" border>最近七天</el-radio>
            <el-radio label="4" border>最近三十天</el-radio>
            <el-radio :disabled="isTrial" label="5" border>自定义</el-radio>
          </el-radio-group>

          <el-date-picker v-if="form.type === '5'"
                          size="small"
                          unlink-panels
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="timeRange"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </label>
        <div class="btn-group">
          <el-button :disabled="isTrial" type="primary" size="mini" @click="toExport">数据导出</el-button>
          <el-button type="primary" size="mini" class="search-btn" @click="refresh">查询</el-button>
        </div>

      </section>
      <section class="content">
        <div class="source-statistics item">
          <div class="top-wrap">
            <h3 class="title">舆情来源网站统计</h3>
          </div>
          <div class="noData" v-show="noSource">暂无数据</div>
          <div id="sourceCharts" class="charts" v-show="!noSource"></div>
        </div>
        <div class="carrier-statistics item">
          <div class="top-wrap">
            <h3 class="title">载体分布统计图</h3>
          </div>
          <div class="noData" v-show="noCarrier">暂无数据</div>
          <div id="carrierCharts" class="charts" v-show="!noCarrier"></div>
        </div>
        <div class="situation-statistics item">
          <div class="top-wrap">
            <h3 class="title">舆情态势统计图</h3>
          </div>
          <div class="noData" v-show="noSituation">暂无数据</div>
          <div id="situationCharts" class="charts" v-show="!noSituation"></div>
        </div>
        <div class="main-statistics item">
          <div class="top-wrap">
            <h3 class="title">舆情主图态势图</h3>
          </div>
          <div class="noData" v-show="noMain">暂无数据</div>
          <div id="mainCharts" class="charts"></div>
        </div>
        <div class="dimensionbar-statistics">
          <div class="top-wrap">
            <h3 class="title">标签词云图</h3>
          </div>
          <div class="noData" v-show="noDimension">暂无数据</div>
          <div id="dimensionCharts" class="charts"></div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { getWebStat, getCarrDis, mediaRanking, monitorStat, markStatistics } from '@/api/dimension'
import { getIsTrial } from '@/utils/auth'
export default {
  data () {
    return ({
      isTrial: true,
      timeRange: '',
      form: {
        type: '4',
        keyWords: '',
        startDate: '',
        endDate: ''
      },
      carrList: [],
      noSource: false,
      noCarrier: false,
      noSituation: false,
      noMain: false,
      noDimension: false,
      tu1: '',
      tu2: '',
      tu3: '',
      tu4: ''
    })
  },
  watch: {
    'timeRange': {
      handler (val) {
        if (val.length > 0) {
          this.form.startDate = this.formatDate(val[0])
          this.form.endDate = this.formatDate(val[1])
        }
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
    this.isTrial = getIsTrial()
    this.tu1 = this.$echarts.init(document.getElementById('sourceCharts'))
    this.tu2 = this.$echarts.init(document.getElementById('carrierCharts'))
    this.tu3 = this.$echarts.init(document.getElementById('situationCharts'))
    this.tu4 = this.$echarts.init(document.getElementById('mainCharts'))
    this.tu5 = this.$echarts.init(document.getElementById('dimensionCharts'))
    this.refresh()
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.tu1.resize()
      this.tu2.resize()
      this.tu3.resize()
      this.tu4.resize()
      this.tu5.resize()
    },
    refresh () {
      this.form.startDate = this.timeRange[0]
      this.form.endDate = this.timeRange[1]
      this.queryWebStat()
      this.queryCarr()
      this.situation()
      this.monStat()
      this.dimension()
    },
    async monStat () {
      this.tu4.showLoading({
        text: '加载中...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await monitorStat(this.form)
      if (res.data === null || res.data.series.length === 0) {
        this.noMain = true
      } else {
        this.noMain = false
        this.main(res)
      }
      this.tu4.hideLoading()
    },
    async queryWebStat () {
      this.tu1.showLoading({
        text: '加载中...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await getWebStat(this.form)
      if (res.data === null || res.data.length === 0) {
        this.noSource = true
      } else {
        this.noSource = false
        this.source(res)
      }
      this.tu1.hideLoading()
    },
    async queryCarr () {
      this.tu2.showLoading({
        text: '加载中...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await getCarrDis(this.form)
      this.carrList = res.data
      if (res.data === null || res.data.length === 0) {
        this.noCarrier = true
      } else {
        this.noCarrier = false
        var totalArr = []
        for(var i=0; i<res.data.series[0].data.length; i++){
          var total = 0
          res.data.series.forEach((item,index)=>{
            total += res.data.series[index].data[i]
          })
          totalArr.push(total)
        }
        var obj = {
          name: '总量',
          type: 'bar',
          barGap: '-100%',
          itemStyle: {
            normal: {
              color: 'rgba(128, 128, 128, 0.3)' // 仍为透明
            }
          },
          data: totalArr
        }
        this.carrList.series.splice(0,0,obj)
        this.carrier(res)
      }
      this.tu2.hideLoading()
    },
    source (res) {
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        toolbox: {
          feature: {
            saveAsImage: {
              pixelRatio: 2
            }
          }
        },
        legend: {
          orient: 'vertical',
          x: 'left',
          data: res.data.legend
        },
        series: [
          {
            name: '网站来源',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 5,
              borderColor: '#fff',
              borderWidth: 1
            },
            color: ['#5470C6','#92CC76','#FAC858','#ED6666','#73C0DE','#3BA272','#FC8452','#9A60B4','#EA7CCC','#879BD8','#BBBBBB'],
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: res.data.series
          }
        ]
      }

      this.tu1.setOption(option)
    },
    carrier (res) {
      console.log(this.carrList)
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        toolbox: {
          feature: {
            saveAsImage: {
              pixelRatio: 2
            }
          }
        },
        color: ['#3372C7', '#C5191B', '#92CC7B', '#6B759A', '#FC8456', '#0898C2', '#FAC861', '#BCBCBC', '#BDE0F5', '#1F1E1C', '#F5A5B4'],
        legend: {
          data: this.carrList.legend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: this.carrList.xAxis
        },
        series: this.carrList.series
      }

      this.tu2.setOption(option)
    },
    async situation () {
      this.tu3.showLoading({
        text: '加载中...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await mediaRanking(this.form)
      if (res.data === null || res.data.length === 0) {
        this.noSituation = true
      } else {
        this.noSituation = false
        var totalArr = []
        if(res.data && res.data.series){
          res.data.series[0].yBadData.forEach((item,index)=>{
            totalArr.push(item+res.data.series[0].yNotBadData[index]+res.data.series[0].yMGData[index])
          })
        }
      }
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        color: ['#3372C7', '#F56C6C', '#ffdb8a'],
        legend: {
          data: ['正面', '敏感', '负面']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: res.data ? res.data.xAxis : []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '总量',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              normal: {
                color: 'rgba(128, 128, 128, 0)' // 仍为透明
              }
            },
            data: totalArr
          },{
            name: '正面',
            type: 'line',
            stack: '总量1',
            symbol: 'none',
            data: res.data ? res.data.series[0].yNotBadData : [],
            smooth: true
          },
          {
            name: '敏感',
            type: 'line',
            stack: '总量2',
            symbol: 'none',
            data: res.data ? res.data.series[0].yMGData : [],
            smooth: true
          },
          {
            name: '负面',
            type: 'line',
            stack: '总量3',
            symbol: 'none',
            data: res.data ? res.data.series[0].yBadData : [],
            smooth: true
          }
        ]
      }

      this.tu3.setOption(option)
      this.tu3.hideLoading()
    },
    main (res) {
      res.data.series.forEach((item,index)=>{
        item.symbol = 'none'
        item.smooth = true
      })
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          type: 'scroll',
          data: res.data.legend
        },
        toolbox: {
          top: 15,
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: res.data.xAxis
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: res.data.series
      }
      this.tu4.setOption(option, true)
    },
    async dimension () {
      this.tu5.showLoading({
        text: '加载中...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.2)',
        zlevel: 0
      })
      const res = await markStatistics(this.form)
      if (res.data === null || res.data.xAxis.length === 0) {
        this.noDimension = true
      } else {
        this.noDimension = false
      }
      this.tu5.hideLoading()
      let option = {
        toolbox: {
          feature: {
            saveAsImage: {
              pixelRatio: 2
            }
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}'
        },
        series: [{
          name: '标签',
          type: 'wordCloud',
          shape: 'diamond',
          size: ['100%', '100%'],
          rotationRange: [0, 0],
          textPadding: 0,
          drawOutOfBound: true,
          autoSize: {
            enable: true,
            minSize: 14
          },
          textStyle: {
            fontFamily: 'sans-serif',
            // Color can be a callback function or a color string
            color: function () {
              // Random color
              return 'rgb(' + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
              ].join(',') + ')'
            }
          },
          emphasis: {
            focus: 'self',
            textStyle: {
              textShadowBlur: 10,
              textShadowColor: '#333'
            }
          },
          data: res.data.series
        }]
      }

      this.tu5.setOption(option)
    },
    toExport () {
      // let routeData = this.$router.resolve({
      //   name: 'export'
      // })
      // window.open(routeData.href, '_blank')
      this.$router.push('/statistics/export')
    },
    formatDate (time) {
      if (typeof (time) === 'string' && time.indexOf('T') === -1) {
        time = time.replace(/-/g, '/').substr(0, time.indexOf('.'))
      }
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let min = date.getMinutes()
      let second = date.getSeconds()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (min < 10) {
        min = '0' + min
      }
      if (second < 10) {
        second = '0' + second
      }
      return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + second
    }
  }
}
</script>

<style lang="scss">
.data-dim {
  width: calc(100vw - 310px);
  padding-right: 20px;
  .dimension-statistics {
    // width: calc(100vw - 280px);
    min-height: 100%;
    .noData{
      width: 100%;
      text-align: center;
      position: absolute;
      color: #898989;
      top: 180px;
    }
    .filter {
      width: 100%;
      background: #fff;
      box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.1);
      border-radius: 2px;
      padding: 30px;

      .title{
        padding: 8px 0;
        color: #565656;
        font-size: 15px;
        position: relative;
        .el-radio--mini.is-bordered .el-radio__label,
        .el-checkbox__input.is-checked + .el-checkbox__label{
          color: #838383;
        }
        .el-radio.is-bordered.is-checked{
          border: 1px solid #3472c7;
        }
        .el-radio__input.is-checked + .el-radio__label{
          color: #3472c7;
        }
        .el-date-editor{
          position: absolute;
          margin-left: 5px;
        }
      }
      .btn-group {
        float: right;
        margin-top: 5px;
        .el-button {
          height: 24px;
          padding: 5px 15px;
          margin-left: 20px;
        }
      }

      .el-radio.is-bordered {
        margin-right: 0px;
        .el-radio__input {
          display: none;
        }
      }
      .el-input {
        width: 300px;
        .el-input__inner {
          height: 24px;
        }
      }

      .el-radio {
        padding: 6px 15px 0 15px;
        .el-radio__label {
          padding-left: 0;
        }
      }
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .top-wrap{
        border-bottom: 1px solid #eaeaea;
        padding: 15px 20px 0;
      }
      .item:nth-child(odd) {
        width: 49%;
        margin-right: 1%;
        height: 380px;
        background: #fff;
        margin-top: 20px;
        box-shadow:2px 2px 10px 0px rgba(207,207,207,0.5);
        border-radius:2px;
        // padding: 20px;
        position: relative;
        .title {
          font-size: 14px;
          color: #666;
          margin-top: 0;
          // margin-bottom: 20px;
        }
        .charts {
          width: 100%;
          height: calc(100% - 60px);
          padding: 20px;
        }
      }
      .item:nth-child(even) {
        width: 49%;
        margin-left: 1%;
        height: 380px;
        background: #fff;
        margin-top: 20px;
        box-shadow:2px 2px 10px 0px rgba(207,207,207,0.5);
        border-radius:2px;
        // padding: 20px;
        position: relative;
        .title {
          font-size: 14px;
          color: #666;
          margin-top: 0;
          // margin-bottom: 20px;
        }
        .charts {
          width: 100%;
          height: calc(100% - 60px);
          padding: 20px;
        }
      }
      .dimensionbar-statistics {
        width: 100%;
        height: 380px;
        background: #fff;
        margin-top: 20px;
        box-shadow:2px 2px 10px 0px rgba(207,207,207,0.5);
        border-radius:2px;
        // padding: 20px;
        position: relative;
        .title {
          font-size: 14px;
          color: #666;
          margin-top: 0;
          // margin-bottom: 20px;
        }
        .charts {
          width: 100%;
          height: calc(100% - 60px);
          padding: 20px;
        }
      }
    }
  }
}
</style>
